
import { Heart, ArrowRight, Music, Sparkles, Instagram } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export const SongHero = () => {
  const navigate = useNavigate();

  const handleOrderClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/place-order');
  };

  return (
    <section className="relative min-h-[calc(100vh-4rem)] flex items-center justify-center overflow-hidden px-4 md:px-0">
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(circle_at_50%_120%,rgba(244,63,94,0.15),rgba(255,255,255,0))]" />
      </div>
      
      <div className="container mx-auto px-4 py-16 md:py-32 relative z-10">
        <div className="text-center max-w-4xl mx-auto">
          {/* Valentine's Special Banner */}
          <div className="mb-6 animate-bounce">
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-love-100 text-love-600 font-semibold text-sm md:text-base">
              <Sparkles className="w-4 h-4" />
              Valentine's Special Offer
              <Sparkles className="w-4 h-4" />
            </div>
          </div>
          
          <div 
            className="inline-flex items-center justify-center gap-2 md:gap-3 px-4 md:px-8 py-3 md:py-4 rounded-full bg-white/90 backdrop-blur-sm border-2 border-love-300 mb-8 md:mb-12 shadow-xl shadow-love-100/50 transform hover:scale-105 transition-all duration-300 group"
          >
            <Music className="w-5 h-5 md:w-6 md:h-6 text-love-500" />
            <span className="text-lg md:text-xl font-bold bg-gradient-to-r from-love-600 via-love-500 to-love-400 bg-clip-text text-transparent">
              Welcome to LoveTunes
            </span>
            <Heart className="w-5 h-5 md:w-6 md:h-6 text-love-500" />
          </div>
          
          <h1 className="text-3xl md:text-7xl font-bold mb-4 md:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-love-700 via-love-600 to-love-500 leading-tight tracking-tight">
            A Love Song,<br />Just for You
          </h1>
          
          <p className="text-base md:text-xl text-gray-600 mb-4 md:mb-6 max-w-2xl mx-auto leading-relaxed px-4">
            LoveTunes transforms your love story into a heart-touching song – handcrafted by real musicians. 
            Delivered in just a few hours!
          </p>

          {/* Instagram Sharing Feature */}
          <p className="flex items-center justify-center gap-2 text-sm md:text-base text-gray-500 mb-8 md:mb-12">
            <Instagram className="w-4 h-4" />
            <span>Perfect for sharing your love story on Instagram</span>
          </p>

          {/* Price comparison section */}
          <div className="flex flex-col items-center gap-2 mb-8 md:mb-12">
            <div className="flex items-center gap-2 text-gray-500">
              <span className="text-lg md:text-xl line-through">₹11,999</span>
              <span className="bg-love-500 text-white px-2 py-1 rounded-md text-sm font-semibold">-58% OFF</span>
            </div>
            <div className="text-2xl md:text-4xl font-bold text-love-600">
              ₹4,999
            </div>
          </div>
          
          <div className="px-4 md:px-0">
            <Button 
              size="lg"
              className="relative bg-love-600 hover:bg-love-700 text-white px-3 md:px-8 py-4 md:py-6 rounded-full text-sm md:text-lg font-semibold transition-all duration-300 hover:scale-105 group w-full md:w-auto
              before:absolute before:inset-0 before:rounded-full before:bg-love-500 before:opacity-0 hover:before:opacity-20 before:transition-opacity
              after:absolute after:inset-0 after:rounded-full after:animate-pulse after:bg-love-400/30 after:opacity-0 hover:after:opacity-100 after:transition-opacity
              overflow-hidden"
              onClick={handleOrderClick}
            >
              <span className="relative z-10 flex items-center justify-center whitespace-normal md:whitespace-nowrap">
                Get Your Valentine's Special Now!
                <ArrowRight className="ml-2 w-4 h-4 md:w-5 md:h-5 group-hover:translate-x-1 transition-transform shrink-0" />
              </span>
            </Button>
          </div>

          {/* Valentine's decoration */}
          <div className="absolute -top-4 -left-4 md:top-0 md:-left-8 w-24 h-24 md:w-32 md:h-32 text-love-400/20">
            <Heart className="w-full h-full animate-pulse" />
          </div>
          <div className="absolute -bottom-4 -right-4 md:bottom-0 md:-right-8 w-24 h-24 md:w-32 md:h-32 text-love-400/20">
            <Heart className="w-full h-full animate-pulse" />
          </div>
        </div>
      </div>
    </section>
  );
};
