// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://pjkaniqhgqkunednhjfb.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBqa2FuaXFoZ3FrdW5lZG5oamZiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg1MDAyNzQsImV4cCI6MjA1NDA3NjI3NH0.epg8-H5EPghawWmRX9i67iXE7o7eH6pd_efLnKWj6mI";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);