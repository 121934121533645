import { Heart, Music } from "lucide-react";

export const Logo = () => {
  return (
    <div className="flex items-center gap-2 p-4">
      <div className="relative">
        <Music className="w-8 h-8 text-love-500 animate-bounce" />
        <Heart className="absolute -bottom-1 -right-1 w-4 h-4 text-love-600 animate-pulse" />
      </div>
      <span className="text-xl font-bold bg-gradient-to-r from-love-600 to-love-400 bg-clip-text text-transparent">
        LoveTunes
      </span>
    </div>
  );
};