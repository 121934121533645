
import { Button } from "@/components/ui/button";
import { ArrowLeft, Copy, Smartphone } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";

export default function Payment() {
  const [isVerifying, setIsVerifying] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const upiId = "officiallovetunes@oksbi";

  const handleCopyUPI = async () => {
    try {
      await navigator.clipboard.writeText(upiId);
      toast({
        title: "UPI ID Copied!",
        description: "The UPI ID has been copied to your clipboard.",
      });
    } catch (err) {
      toast({
        title: "Failed to copy",
        description: "Please copy the UPI ID manually.",
        variant: "destructive",
      });
    }
  };

  const handleConfirmPayment = () => {
    setIsVerifying(true);
    toast({
      title: "Payment Verification Started",
      description: "We are verifying your payment. Please wait...",
    });

    // Simulate payment verification
    setTimeout(() => {
      setIsVerifying(false);
      toast({
        title: "Thank you for choosing us! 💝",
        description: "Upon verification, your love song will be delivered within one hour. We might make your partner go crazy (in love with you of course 😉)",
      });
      
      // Add a small delay before redirecting to home page
      setTimeout(() => {
        navigate('/');
      }, 3000); // Redirect after 3 seconds
    }, 3000);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-love-50 via-white to-white p-4 md:p-8">
      <div className="max-w-2xl mx-auto">
        <Link 
          to="/place-order" 
          className="inline-flex items-center text-love-600 hover:text-love-700 mb-6 group"
        >
          <ArrowLeft className="w-4 h-4 mr-2 group-hover:-translate-x-1 transition-transform" />
          Back to Order Form
        </Link>

        <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8 space-y-8">
          <div className="text-center">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
              Complete Your Payment
            </h1>
            <p className="text-gray-600">
              Pay ₹4,999 using any UPI app of your choice
            </p>
          </div>

          {/* UPI ID Section */}
          <div className="bg-love-50 p-4 rounded-xl">
            <div className="flex items-center justify-between gap-4 mb-2">
              <div className="flex-1">
                <p className="text-sm text-gray-600 mb-1">UPI ID</p>
                <p className="font-mono text-lg font-medium">{upiId}</p>
              </div>
              <Button
                variant="outline"
                size="icon"
                onClick={handleCopyUPI}
                className="shrink-0"
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
          </div>

          {/* Supported Apps */}
          <div className="space-y-4">
            <p className="text-center text-sm text-gray-600">Supported Apps</p>
            <div className="flex justify-center items-center gap-8">
              <div className="flex flex-col items-center gap-2">
                <div className="w-12 h-12 bg-[#00C3A5] rounded-full flex items-center justify-center">
                  <Smartphone className="w-6 h-6 text-white" />
                </div>
                <span className="text-xs">PhonePe</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <div className="w-12 h-12 bg-[#4285F4] rounded-full flex items-center justify-center">
                  <Smartphone className="w-6 h-6 text-white" />
                </div>
                <span className="text-xs">Google Pay</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <div className="w-12 h-12 bg-[#00B9F1] rounded-full flex items-center justify-center">
                  <Smartphone className="w-6 h-6 text-white" />
                </div>
                <span className="text-xs">Paytm</span>
              </div>
            </div>
          </div>

          {/* Confirm Payment Button */}
          <Button 
            onClick={handleConfirmPayment}
            disabled={isVerifying}
            className="w-full bg-love-600 hover:bg-love-700 text-white py-6 rounded-full text-lg font-semibold transition-all duration-300
            disabled:bg-love-400"
          >
            {isVerifying ? "Verifying Payment..." : "Confirm Payment"}
          </Button>

          <p className="text-center text-sm text-gray-500">
            Please make the payment before confirming. Our team will verify your payment and process your order.
          </p>
        </div>
      </div>
    </div>
  );
}
