
import { SongHero } from "@/components/SongHero";
import { HowItWorks } from "@/components/HowItWorks";
import { Testimonials } from "@/components/Testimonials";
import { AnimatedHearts } from "@/components/AnimatedHearts";
import { InstagramFeature } from "@/components/InstagramFeature";
import { Logo } from "@/components/Logo";
import { SampleSong } from "@/components/SampleSong";
import { FileText, Lock, Shield, ChevronDown } from "lucide-react";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

const Index = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-love-50 via-white to-white overflow-hidden">
      {/* Fixed Logo */}
      <div className="fixed top-0 left-0 z-50 bg-white/80 backdrop-blur-sm w-full shadow-sm">
        <Logo />
      </div>
      
      {/* Main Content with Padding for Fixed Logo */}
      <div className="pt-16">
        {/* Romantic Background Elements */}
        <div className="fixed inset-0 pointer-events-none overflow-hidden">
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-love-50/50 via-white/30 to-white/50" />
          
          {/* Decorative Elements - Adjusted for Mobile */}
          <div className="absolute top-20 left-5 w-32 h-32 md:w-64 md:h-64 bg-love-100/20 rounded-full blur-3xl" />
          <div className="absolute top-40 right-5 w-48 h-48 md:w-96 md:h-96 bg-love-200/10 rounded-full blur-3xl" />
          <div className="absolute bottom-20 left-1/4 w-36 h-36 md:w-72 md:h-72 bg-love-100/20 rounded-full blur-3xl" />
          
          {/* Romantic Patterns */}
          <div className="absolute inset-0 opacity-5"
               style={{
                 backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 30c-4-4-8-8-12-8s-8 4-12 8 4 8 8 12 8-4 12-8zm0 0c4 4 8 8 12 8s8-4 12-8-4-8-8-12-8 4-12 8z' fill='%23f43f5e' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E")`,
                 backgroundSize: '60px 60px'
               }} />
        </div>

        <AnimatedHearts />
        <SongHero />
        <SampleSong />
        <HowItWorks />
        <InstagramFeature />
        <Testimonials />

        {/* FAQ, Privacy Policy, and Terms Section */}
        <div className="container mx-auto px-4 py-16 space-y-16">
          {/* FAQs Section */}
          <section id="faq" className="bg-white/80 backdrop-blur-sm rounded-3xl p-8 shadow-xl">
            <div className="flex items-center gap-3 mb-12">
              <div className="bg-love-100 p-3 rounded-2xl">
                <FileText className="w-8 h-8 text-love-500" />
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900">Frequently Asked Questions</h2>
            </div>
            <div className="max-w-3xl mx-auto">
              <Accordion type="single" collapsible className="space-y-4">
                {[
                  {
                    q: "What is this service about?",
                    a: "We create personalized love songs based on your unique love story. Each song is written, composed, and recorded by real human musicians, making it the perfect one-of-a-kind Valentine's gift!"
                  },
                  {
                    q: "How does it work?",
                    a: "Fill out a quick form with details like your partner's name, your love story, and any special moments. Our professional musicians will craft a custom song just for you. You will receive your song via email and WhatsApp within 5-6 hours."
                  },
                  {
                    q: "How much does a custom song cost?",
                    a: "The price for a personalized song is ₹4,999."
                  },
                  {
                    q: "Can I request changes to my song?",
                    a: "Yes! We offer up to 2 free revisions to ensure your song is exactly how you want it. You can request changes by contacting customer support."
                  },
                  {
                    q: "Can I get a refund if I don't like my song?",
                    a: "Since each song is uniquely crafted for you, we do not offer refunds. However, you can request 2 free modifications to improve your song."
                  },
                  {
                    q: "How will I receive my song?",
                    a: "You will get a high-quality MP3 file delivered to your email and WhatsApp within 5-6 hours."
                  },
                  {
                    q: "Can I choose the music style?",
                    a: "Yes! You can mention in the form whether you prefer a romantic acoustic feel, upbeat pop, or a Bollywood-style melody, and we will customize it accordingly."
                  },
                  {
                    q: "Can I surprise my partner with this gift?",
                    a: "Absolutely! You can provide your partner's contact details, and we will send them the song as a surprise gift."
                  },
                  {
                    q: "Do you use AI to create the songs?",
                    a: "No! All our songs are handcrafted by real human musicians to ensure emotional depth, originality, and personal touch."
                  },
                  {
                    q: "How can I contact customer support?",
                    a: "You can reach us via WhatsApp or Email for any queries or modifications."
                  }
                ].map((faq, index) => (
                  <AccordionItem key={index} value={`item-${index}`} className="border border-gray-200 rounded-xl px-6">
                    <AccordionTrigger className="hover:no-underline">
                      <span className="text-left text-lg font-semibold text-gray-900">{faq.q}</span>
                    </AccordionTrigger>
                    <AccordionContent className="text-gray-600">{faq.a}</AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </section>

          {/* Legal Links */}
          <div className="flex flex-wrap justify-center gap-4">
            {/* Privacy Policy Drawer */}
            <Drawer>
              <DrawerTrigger asChild>
                <Button variant="outline" className="flex items-center gap-2">
                  <Lock className="w-4 h-4" />
                  Privacy Policy
                </Button>
              </DrawerTrigger>
              <DrawerContent className="max-h-[90vh] overflow-y-auto">
                <DrawerHeader>
                  <DrawerTitle className="flex items-center gap-2">
                    <Lock className="w-5 h-5 text-love-500" />
                    Privacy Policy
                  </DrawerTitle>
                </DrawerHeader>
                <div className="px-4 pb-8">
                  <div className="prose max-w-none text-gray-600">
                    <h3 className="text-lg font-semibold text-gray-900">1. Information We Collect</h3>
                    <p>When you place an order, we collect:</p>
                    <ul className="list-disc pl-6 mb-4">
                      <li>Your Name & Partner's Name (to personalize your song)</li>
                      <li>Email & Phone Number (to send you the song)</li>
                      <li>Your Love Story & Special Memories (to make your song unique)</li>
                    </ul>
                    <p>We do not share or sell your personal data to any third party.</p>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">2. How We Use Your Information</h3>
                    <ul className="list-disc pl-6">
                      <li>To create and deliver your personalized song</li>
                      <li>To contact you for revisions or support</li>
                      <li>To send occasional offers and updates (you can opt out anytime)</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">3. How We Protect Your Data</h3>
                    <p>Your information is stored securely and used only for your song request.</p>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">4. How Long Do We Keep Your Data?</h3>
                    <p>We store your song request details for 30 days in case you need modifications. After that, all personal data is deleted.</p>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">5. Third-Party Services</h3>
                    <p>We use WhatsApp and Email to deliver your song, but we do not share your details with anyone else.</p>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">6. Your Rights</h3>
                    <ul className="list-disc pl-6">
                      <li>You can request to delete your data at any time</li>
                      <li>You can unsubscribe from promotional messages anytime</li>
                    </ul>
                  </div>
                </div>
              </DrawerContent>
            </Drawer>

            {/* Terms & Conditions Drawer */}
            <Drawer>
              <DrawerTrigger asChild>
                <Button variant="outline" className="flex items-center gap-2">
                  <Shield className="w-4 h-4" />
                  Terms & Conditions
                </Button>
              </DrawerTrigger>
              <DrawerContent className="max-h-[90vh] overflow-y-auto">
                <DrawerHeader>
                  <DrawerTitle className="flex items-center gap-2">
                    <Shield className="w-5 h-5 text-love-500" />
                    Terms & Conditions
                  </DrawerTitle>
                </DrawerHeader>
                <div className="px-4 pb-8">
                  <div className="prose max-w-none text-gray-600">
                    <h3 className="text-lg font-semibold text-gray-900">1. Service Agreement</h3>
                    <p>By placing an order, you agree to these terms and conditions. We provide custom-made songs based on your inputs and deliver them within 5-6 hours.</p>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">2. Pricing & Payment</h3>
                    <ul className="list-disc pl-6">
                      <li>The price for a custom song is ₹4,999</li>
                      <li>Full payment must be made at the time of order placement</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">3. No Refund Policy</h3>
                    <p>Since each song is uniquely created for you, all sales are final and non-refundable. However, you can request up to 2 free modifications if needed.</p>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">4. Song Modifications</h3>
                    <ul className="list-disc pl-6">
                      <li>You can request up to 2 changes to the lyrics or melody after delivery</li>
                      <li>Modifications must be requested within 24 hours of receiving the song</li>
                      <li>Additional modifications beyond 2 will be chargeable</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">5. Song Rights & Usage</h3>
                    <ul className="list-disc pl-6">
                      <li>The song is created exclusively for you, and you have full rights to share and use it</li>
                      <li>We retain the right to feature anonymous snippets of songs as samples on our website (without personal details)</li>
                      <li>The song cannot be used for commercial purposes without prior permission</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">6. Delivery Time</h3>
                    <ul className="list-disc pl-6">
                      <li>Songs will be delivered via email & WhatsApp within 5-6 hours</li>
                      <li>Delays may occur due to high demand, but we ensure delivery within the same day</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">7. Customer Responsibilities</h3>
                    <ul className="list-disc pl-6">
                      <li>Provide accurate and complete details to help us create the best song for you</li>
                      <li>Respect the modification limit and request changes within the allowed timeframe</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">8. Dispute Resolution</h3>
                    <p>If you have any concerns, please contact our support team first before escalating a complaint.</p>

                    <h3 className="text-lg font-semibold text-gray-900 mt-6">9. Changes to Terms</h3>
                    <p>We reserve the right to update these terms & conditions as needed.</p>
                  </div>
                </div>
              </DrawerContent>
            </Drawer>
          </div>
        </div>
      </div>

      {/* Additional Decorative Elements */}
      <div className="fixed bottom-0 left-0 w-full h-32 bg-gradient-to-t from-white to-transparent pointer-events-none" />
    </div>
  );
};

export default Index;
