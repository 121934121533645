import { Music, Play } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState, useRef } from "react";

export const SampleSong = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const sampleSongUrl = "https://pjkaniqhgqkunednhjfb.supabase.co/storage/v1/object/public/audio/sample-love-song.mp3";
  
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handlePlayClick = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(sampleSongUrl);
      audioRef.current.addEventListener('ended', () => setIsPlaying(false));
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="w-full max-w-md mx-auto my-8 p-6 bg-white/90 backdrop-blur-sm rounded-xl shadow-xl border-2 border-love-200 hover:border-love-300 transition-all duration-300">
      <div className="flex items-center gap-3 mb-4">
        <Music className="w-6 h-6 text-love-500" />
        <h3 className="text-xl font-semibold text-love-700">Sample Love Song</h3>
      </div>
      
      <div className="w-full bg-love-50 rounded-lg p-4">
        <p className="text-love-600 mb-4">
          Listen to our sample love song to get an idea of our musical style
        </p>
        
        <Button 
          onClick={handlePlayClick}
          className="w-full bg-love-500 hover:bg-love-600 text-white flex items-center justify-center gap-2 py-6"
        >
          <Play className={`w-6 h-6 ${isPlaying ? 'animate-pulse' : ''}`} />
          {isPlaying ? 'Playing...' : 'Play Sample Song'}
        </Button>
      </div>
    </div>
  );
};