import { Music, Heart, Send } from "lucide-react";

const steps = [
  {
    icon: Heart,
    title: "Tell Your Story",
    description: "Share your partner's name and a special memory that makes your love unique with LoveTunes.",
  },
  {
    icon: Music,
    title: "We Create Your Song",
    description: "Our professional LoveTunes musicians craft a beautiful, personalized song just for you.",
  },
  {
    icon: Send,
    title: "Quick Delivery",
    description: "Receive your custom LoveTunes song via email & WhatsApp within just 1 hour.",
  },
];

export const HowItWorks = () => {
  return (
    <section className="py-16 md:py-24 bg-gradient-to-b from-white to-love-50/30" id="how-it-works">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12 md:mb-16">
          <h2 className="text-2xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-love-700 to-love-500 bg-clip-text text-transparent">
            How LoveTunes Works
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-base md:text-lg px-4">
            Three simple steps to create your perfect love song
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-6 md:gap-8 max-w-5xl mx-auto">
          {steps.map((step, index) => (
            <div 
              key={index}
              className="relative group p-6 md:p-8 rounded-2xl bg-white border border-gray-100 hover:border-love-200 transition-all duration-300 hover:shadow-xl"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-love-50/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
              
              <div className="relative">
                <div className="w-12 h-12 md:w-16 md:h-16 rounded-full bg-love-100 flex items-center justify-center mb-4 md:mb-6 mx-auto group-hover:scale-110 transition-transform duration-300">
                  <step.icon className="w-6 h-6 md:w-8 md:h-8 text-love-600" />
                </div>
                
                <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-gray-900">
                  {step.title}
                </h3>
                
                <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};