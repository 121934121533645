
import { Star } from "lucide-react";
import { Avatar } from "@/components/ui/avatar";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";

interface Testimonial {
  name: string;
  image: string;
  location: string;
  message: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Priya & Rahul",
    image: "/placeholder.svg",
    location: "Mumbai, Maharashtra",
    message: "LoveTunes created the most beautiful song for our first anniversary. The melody captured our journey from college sweethearts to soulmates. Every note tells our story perfectly! ❤️"
  },
  {
    name: "Arjun & Divya",
    image: "/placeholder.svg",
    location: "Bangalore, Karnataka",
    message: "From arranged marriage to true love - our LoveTunes song beautifully captures how we fell in love over coffee meetings. Now it's our morning alarm tune! Worth every rupee!"
  },
  {
    name: "Karthik & Meera",
    image: "/placeholder.svg",
    location: "Chennai, Tamil Nadu",
    message: "The song they created for my proposal was magical! They perfectly captured our long-distance love story. When I played it during the proposal, she had happy tears! 🎵💍"
  },
  {
    name: "Aisha & Zain",
    image: "/placeholder.svg",
    location: "Hyderabad, Telangana",
    message: "Our interfaith love story needed something special, and LoveTunes delivered! They created a beautiful fusion of our cultural music styles. It's become our family's favorite! 🎶"
  },
  {
    name: "Neha & Vikram",
    image: "/placeholder.svg",
    location: "Delhi, NCR",
    message: "Five years of dating deserved a special celebration. The song they composed brought tears to our eyes - it had references to all our special moments! Simply beautiful ✨"
  },
  {
    name: "Sarah & Rohan",
    image: "/placeholder.svg",
    location: "Pune, Maharashtra",
    message: "As childhood friends turned lovers, our story was unique. LoveTunes captured every detail, from school memories to our wedding day. It's like our life's soundtrack! 💑"
  },
  {
    name: "Maya & Aditya",
    image: "/placeholder.svg",
    location: "Kolkata, West Bengal",
    message: "The song they created for our 10th anniversary brought back so many memories. From our first date to our children's births - everything was woven into the melody beautifully! 🌟"
  },
  {
    name: "Riya & Sameer",
    image: "/placeholder.svg",
    location: "Jaipur, Rajasthan",
    message: "Our destination wedding needed something unique. LoveTunes created a perfect blend of modern love and traditional elements. Everyone wanted a copy of our song! 💫"
  },
  {
    name: "Ananya & Dev",
    image: "/placeholder.svg",
    location: "Ahmedabad, Gujarat",
    message: "They turned our quirky love story into the most beautiful melody. Every inside joke, every special moment was included. It's not just a song, it's our story in music! 🎼"
  },
  {
    name: "Kavya & Nikhil",
    image: "/placeholder.svg",
    location: "Goa",
    message: "Beach lovers who met during a sunset - our story needed something special. The song captures the sound of waves and our journey perfectly. Pure magic! 🌊❤️"
  },
  {
    name: "Ishaan & Tara",
    image: "/placeholder.svg",
    location: "Chandigarh, Punjab",
    message: "From college rivals to life partners, our story had everything! LoveTunes created a song that makes us laugh and cry. Now we play it at every anniversary! 💝"
  },
  {
    name: "Pooja & Raj",
    image: "/placeholder.svg",
    location: "Indore, MP",
    message: "The way they incorporated our favorite Bollywood songs into our personal love story was incredible. It's the perfect blend of nostalgia and romance! 🎵💕"
  },
  {
    name: "Leela & Aryan",
    image: "/placeholder.svg",
    location: "Kochi, Kerala",
    message: "Our story of meeting at a book club needed a special touch. They created a melody that feels like a romantic novel turned into music. Simply magical! 📚❤️"
  },
  {
    name: "Amit & Nisha",
    image: "/placeholder.svg",
    location: "Lucknow, UP",
    message: "Our families were hesitant about our relationship, but when they heard our love song, they understood our bond. The melody bridged cultural differences beautifully! 🎵💫"
  },
  {
    name: "Siddharth & Aisha",
    image: "/placeholder.svg",
    location: "Bhopal, MP",
    message: "The song captured our journey from medical school to marriage perfectly. Every lyric reminds us of our hospital romance and late-night study sessions! 👨‍⚕️❤️"
  },
  {
    name: "Varun & Mira",
    image: "/placeholder.svg",
    location: "Dehradun, Uttarakhand",
    message: "Our trek to Kedarnath brought us together, and LoveTunes wove that mountain magic into our song. The melody feels like fresh mountain air! 🏔️💕"
  },
  {
    name: "Kabir & Zara",
    image: "/placeholder.svg",
    location: "Srinagar, Kashmir",
    message: "They beautifully blended Kashmiri folk music with modern love songs. Our families play it at every gathering now! A perfect cultural fusion 🎶💑"
  },
  {
    name: "Rajesh & Prerna",
    image: "/placeholder.svg",
    location: "Ranchi, Jharkhand",
    message: "From engineering college rivals to life partners - our song tells this transformation so beautifully. Everyone at our wedding wanted the song's copy! 🎓💘"
  },
  {
    name: "Vivek & Ananya",
    image: "/placeholder.svg",
    location: "Vadodara, Gujarat",
    message: "They included the sound of garba beats in our love song! Perfect blend of tradition and romance. We dance to it every Navratri! 💃🕺"
  },
  {
    name: "Kunal & Riya",
    image: "/placeholder.svg",
    location: "Bhubaneswar, Odisha",
    message: "Our story of meeting at a classical music concert needed special treatment. They created magic by mixing Odissi elements with modern romance! 🎻❤️"
  },
  {
    name: "Arun & Lakshmi",
    image: "/placeholder.svg",
    location: "Coimbatore, TN",
    message: "The way they incorporated Carnatic music elements into our love song was brilliant! A perfect blend of tradition and modern love 🎵✨"
  },
  {
    name: "Jay & Meera",
    image: "/placeholder.svg",
    location: "Nashik, Maharashtra",
    message: "From wine tasting to wedding planning, our song captures every moment. The tune reminds us of vineyard sunsets and promises made! 🍷💑"
  },
  {
    name: "Dhruv & Ishita",
    image: "/placeholder.svg",
    location: "Shimla, HP",
    message: "Our winter romance needed a cozy melody. The song feels like a warm hug on a snowy day! Perfect for our mountain love story ⛄️❤️"
  },
  {
    name: "Omar & Sana",
    image: "/placeholder.svg",
    location: "Surat, Gujarat",
    message: "The song perfectly captures our diamond business romance! They even added sounds of bustling markets. So creative and personal! 💎💖"
  },
  {
    name: "Rohit & Tanvi",
    image: "/placeholder.svg",
    location: "Nagpur, Maharashtra",
    message: "From coaching classes to life partners - our song tells this beautiful journey. The tune brings back memories of solving math problems together! 📚❤️"
  },
  {
    name: "Krishna & Radha",
    image: "/placeholder.svg",
    location: "Mathura, UP",
    message: "They created a modern version of eternal love! The blend of flute and contemporary beats makes our song so special. Everyone says it's divine! 🎺💫"
  },
  {
    name: "Aryan & Myra",
    image: "/placeholder.svg",
    location: "Gangtok, Sikkim",
    message: "Our cross-cultural love story needed something unique. They mixed Sikkimese folk tunes with modern romance perfectly! 🏔️💝"
  },
  {
    name: "Farhan & Kiara",
    image: "/placeholder.svg",
    location: "Mangalore, Karnataka",
    message: "From beach walks to coffee estate visits, our song captures coastal Karnataka's romance beautifully! The rhythm feels like ocean waves! 🌊❤️"
  },
  {
    name: "Shaurya & Pari",
    image: "/placeholder.svg",
    location: "Udaipur, Rajasthan",
    message: "Our palace wedding needed a royal touch. The song mixes Rajasthani folk with modern love perfectly. Truly majestic! 👑💕"
  },
  {
    name: "Advait & Zena",
    image: "/placeholder.svg",
    location: "Pondicherry, TN",
    message: "French colonial charm meets Indian romance in our song! Perfect for our Indo-French love story. C'est magnifique! 🇫🇷❤️"
  },
  {
    name: "Neil & Sia",
    image: "/placeholder.svg",
    location: "Shillong, Meghalaya",
    message: "They captured the essence of our rock music love story! The song has hints of Khasi folk music too. Simply incredible! 🎸💘"
  },
  {
    name: "Veer & Amaira",
    image: "/placeholder.svg",
    location: "Amritsar, Punjab",
    message: "From Golden Temple visits to dhaba dates, our song has everything! The bhangra beats make it perfect for every celebration! 🥁💃"
  },
  {
    name: "Rehan & Aaliya",
    image: "/placeholder.svg",
    location: "Lucknow, UP",
    message: "Our love bloomed over Lucknowi kebabs and poetry. The song captures that nawabi romance perfectly! Truly magical! 🌙✨"
  },
  {
    name: "Yash & Diya",
    image: "/placeholder.svg",
    location: "Raipur, Chhattisgarh",
    message: "From tribal music to modern beats, our song represents our roots and love beautifully. Everyone's favorite at family gatherings! 🎶💫"
  },
  {
    name: "Aarav & Mishti",
    image: "/placeholder.svg",
    location: "Darjeeling, WB",
    message: "Tea gardens, toy trains, and true love - our song has it all! The misty mountain romance comes alive in every note! 🚂❤️"
  },
  {
    name: "Kabir & Noor",
    image: "/placeholder.svg",
    location: "Ajmer, Rajasthan",
    message: "Our first meeting at the dargah turned into eternal love. The song beautifully mixes Sufi elements with modern tunes! 🕌💫"
  },
  {
    name: "Virat & Anushka",
    image: "/placeholder.svg",
    location: "Dharamshala, HP",
    message: "Cricket brought us together, and LoveTunes made it melodious! They even added crowd cheers to our love song. Howzzat for romance! 🏏❤️"
  },
  {
    name: "Arjun & Kiara",
    image: "/placeholder.svg",
    location: "Mysore, Karnataka",
    message: "Palace lights and royal heritage - our song captures the grandeur of our love story. It's become our palace's unofficial anthem! 👑💕"
  },
  {
    name: "Shiv & Parvati",
    image: "/placeholder.svg",
    location: "Varanasi, UP",
    message: "From Ganga aarti to morning yoga, our spiritual connection shines through every note. Divine love in musical form! 🕉️💝"
  },
  {
    name: "Aditya & Zara",
    image: "/placeholder.svg",
    location: "Pushkar, Rajasthan",
    message: "Desert romance and camel safaris - our song brings back memories of starlit nights and sand dunes. Pure magic! 🐪✨"
  },
  {
    name: "Rohan & Aisha",
    image: "/placeholder.svg",
    location: "Ooty, TN",
    message: "Hill station romance captured in melody! They even added the sound of toy train whistles. Every note feels like mountain breeze! 🚂💕"
  },
  {
    name: "Ved & Tara",
    image: "/placeholder.svg",
    location: "Rishikesh, Uttarakhand",
    message: "Yoga brought us together, and our song reflects that spiritual bond. The sound of flowing Ganga makes it extra special! 🧘‍♀️❤️"
  },
  {
    name: "Karan & Simran",
    image: "/placeholder.svg",
    location: "Patiala, Punjab",
    message: "DDLJ fans who found real love! Our song has hints of classic Bollywood with a modern Punjabi twist. Raj and Simran would approve! 🎬💑"
  },
  {
    name: "Abir & Ruhi",
    image: "/placeholder.svg",
    location: "Port Blair, Andaman",
    message: "Island romance set to perfect melody! They captured the sound of waves and seagulls in our love song. Paradise found! 🏖️💘"
  },
  {
    name: "Shaan & Myra",
    image: "/placeholder.svg",
    location: "Mussoorie, Uttarakhand",
    message: "From writing poems to sharing dreams, our literary love story got its perfect soundtrack. Every word feels like poetry! 📚💫"
  },
  {
    name: "Arnav & Khushi",
    image: "/placeholder.svg",
    location: "Guwahati, Assam",
    message: "Tea gardens and Bihu rhythms make our love song unique! Perfect blend of Assamese culture and modern romance! 🍵💝"
  },
  {
    name: "Sahil & Naina",
    image: "/placeholder.svg",
    location: "Manali, HP",
    message: "Snowfall and first kiss - our winter romance got its perfect melody! The song feels like warm cuddles on cold nights! ❄️❤️"
  },
  {
    name: "Raghav & Pihu",
    image: "/placeholder.svg",
    location: "Agra, UP",
    message: "Our love story started at Taj Mahal. The song captures that monumental love perfectly! A musical tribute to eternal romance! 🏰💕"
  },
  {
    name: "Kabir & Aaliyah",
    image: "/placeholder.svg",
    location: "Dalhousie, HP",
    message: "From pine forests to misty mornings, our hill station romance got its perfect tune! Every note feels like mountain air! 🌲💫"
  },
  {
    name: "Vivaan & Zara",
    image: "/placeholder.svg",
    location: "Puducherry, TN",
    message: "French cafes and Tamil poetry - our cross-cultural love story needed this unique melody! C'est l'amour! 🇫🇷💝"
  },
  {
    name: "Aryan & Mira",
    image: "/placeholder.svg",
    location: "Hampi, Karnataka",
    message: "Ancient ruins and modern love - our song bridges centuries! The fusion of classical and contemporary is just perfect! 🏛️❤️"
  },
  {
    name: "Aadi & Nyra",
    image: "/placeholder.svg",
    location: "Mahabaleshwar, Maharashtra",
    message: "Strawberry farms and misty valleys - our song brings back memories of sweet beginnings! Every note tastes like fresh berries! 🍓💕"
  },
  {
    name: "Reyansh & Aanya",
    image: "/placeholder.svg",
    location: "Kovalam, Kerala",
    message: "Beach sunsets and backwater cruises - our coastal romance got its perfect rhythm! The melody flows like gentle waves! 🌊💘"
  },
  {
    name: "Viaan & Kyra",
    image: "/placeholder.svg",
    location: "Lonavala, Maharashtra",
    message: "Monsoon magic and chai dates - our song captures every rainy day romance perfectly! It's like music meets monsoon! ☔️❤️"
  },
  {
    name: "Shivay & Anvi",
    image: "/placeholder.svg",
    location: "Khajuraho, MP",
    message: "Art brought us together, and our song is a masterpiece too! Perfect blend of classical heritage and modern love! 🎨💫"
  },
  {
    name: "Aarav & Isha",
    image: "/placeholder.svg",
    location: "Kodaikanal, TN",
    message: "From lakeside walks to stargazing nights, our hill station love story got its heavenly tune! Pure mountain magic! ⭐️💕"
  },
  {
    name: "Vihaan & Samaira",
    image: "/placeholder.svg",
    location: "Mount Abu, Rajasthan",
    message: "Desert meets hills in our love story, and the song captures this unique blend perfectly! Every note feels like sunset at Nakki Lake! 🌅❤️"
  }
];

export const Testimonials = () => {
  const plugin = useRef(
    Autoplay({ delay: 4000, stopOnInteraction: true })
  );

  return (
    <section className="py-24 bg-gradient-to-b from-love-50/50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-gradient-to-r from-love-700 via-love-600 to-love-500 bg-clip-text text-transparent">
            Love Stories from Happy Couples
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Join thousands of couples who have made their love stories eternal through music
          </p>
        </div>

        <Carousel
          opts={{
            align: "start",
            loop: true,
          }}
          plugins={[plugin.current]}
          className="w-full"
        >
          <CarouselContent className="-ml-2 md:-ml-4">
            {testimonials.map((testimonial, index) => (
              <CarouselItem key={index} className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3">
                <div className="h-full bg-white/80 backdrop-blur-sm p-6 md:p-8 rounded-2xl border border-love-100 shadow-lg">
                  <div className="flex items-center mb-6">
                    <Avatar className="h-12 w-12 border-2 border-love-200">
                      <div className="bg-love-100 w-full h-full flex items-center justify-center text-love-600 text-lg font-semibold">
                        {testimonial.name[0]}
                      </div>
                    </Avatar>
                    <div className="ml-4">
                      <h3 className="font-semibold text-gray-800">{testimonial.name}</h3>
                      <p className="text-sm text-gray-500">{testimonial.location}</p>
                    </div>
                  </div>

                  <div className="mb-4 flex">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 fill-love-500 text-love-500" />
                    ))}
                  </div>

                  <p className="text-gray-600 leading-relaxed line-clamp-6">
                    "{testimonial.message}"
                  </p>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </section>
  );
};
