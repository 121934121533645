
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ArrowLeft } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";

const formSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters"),
  email: z.string().email("Please enter a valid email"),
  phone: z.string().min(10, "Please enter a valid phone number"),
  occasion: z.string().min(2, "Please specify the occasion"),
  message: z.string().min(20, "Please provide more details about your love story (minimum 20 characters)"),
  preferences: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

export default function PlaceOrder() {
  const navigate = useNavigate();
  const { toast } = useToast();
  
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      occasion: "",
      message: "",
      preferences: "",
    },
  });

  async function onSubmit(values: FormValues) {
    try {
      const { error } = await supabase
        .from('orders')
        .insert({
          name: values.name,
          email: values.email,
          phone: values.phone,
          occasion: values.occasion,
          message: values.message,
          preferences: values.preferences || null,
        });

      if (error) throw error;

      toast({
        title: "Order details saved!",
        description: "Proceeding to payment...",
      });

      navigate("/payment");
    } catch (error) {
      console.error('Error saving order:', error);
      toast({
        title: "Error saving order",
        description: "Please try again. If the problem persists, contact support.",
        variant: "destructive",
      });
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-love-50 via-white to-white p-4 md:p-8">
      <div className="max-w-2xl mx-auto">
        <Link 
          to="/" 
          className="inline-flex items-center text-love-600 hover:text-love-700 mb-6 group"
        >
          <ArrowLeft className="w-4 h-4 mr-2 group-hover:-translate-x-1 transition-transform" />
          Back to Home
        </Link>

        <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-2">
            Share Your Love Story
          </h1>
          <p className="text-gray-600 mb-6">
            Tell us about your special moments, and our musicians will craft them into a unique melody just for you
          </p>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Your Name</FormLabel>
                    <FormControl>
                      <Input placeholder="John Doe" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input type="email" placeholder="john@example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <Input placeholder="+91 1234567890" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="occasion"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Special Occasion</FormLabel>
                    <FormControl>
                      <Input placeholder="Anniversary, Birthday, Wedding, etc." {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Your Love Story</FormLabel>
                    <FormControl>
                      <Textarea 
                        placeholder="Tell us about your journey together, special moments, or what makes your love unique..."
                        className="min-h-[150px]"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="preferences"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Music Preferences (Optional)</FormLabel>
                    <FormControl>
                      <Textarea 
                        placeholder="Any specific genre, style, or musical elements you'd like us to include..."
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button 
                type="submit"
                className="w-full bg-love-600 hover:bg-love-700 text-white py-6 rounded-full text-lg font-semibold transition-colors"
              >
                Proceed to Payment – ₹4,999
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
