import React, { useEffect, useRef, useState } from 'react';

interface Heart {
  id: number;
  x: number;
  y: number;
  scale: number;
  opacity: number;
  rotation: number;
}

export const AnimatedHearts = () => {
  const [hearts, setHearts] = useState<Heart[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const nextHeartId = useRef(0);

  const createHeart = (x: number, y: number) => {
    const heart: Heart = {
      id: nextHeartId.current++,
      x,
      y,
      scale: 0.8 + Math.random() * 0.7,
      opacity: 0.9,
      rotation: -30 + Math.random() * 60,
    };
    setHearts(prev => [...prev, heart]);
    setTimeout(() => {
      setHearts(prev => prev.filter(h => h.id !== heart.id));
    }, 2000); // Decreased from 8000ms back to 2000ms for faster disappearance
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const generateRandomHeart = () => {
      const rect = container.getBoundingClientRect();
      const x = Math.random() * rect.width;
      const y = rect.height + 50;
      createHeart(x, y);
    };

    // Background hearts - increased frequency
    const interval = setInterval(() => {
      for (let i = 0; i < 2; i++) {
        generateRandomHeart();
      }
    }, 200); // Decreased from 1000ms back to 200ms for faster generation

    // Mouse move handler
    const handleMouseMove = (e: MouseEvent) => {
      if (Math.random() > 0.7) {
        createHeart(e.clientX, e.clientY);
      }
    };

    // Click handler
    const handleClick = (e: MouseEvent) => {
      const radius = 30;
      for (let i = 0; i < 8; i++) {
        const angle = (i / 8) * Math.PI * 2;
        const x = e.clientX + Math.cos(angle) * radius;
        const y = e.clientY + Math.sin(angle) * radius;
        createHeart(x, y);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('click', handleClick);

    return () => {
      clearInterval(interval);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="fixed inset-0 pointer-events-none z-50"
      style={{ overflow: 'hidden' }}
    >
      {hearts.map(heart => (
        <div
          key={heart.id}
          className="absolute transition-all duration-[2000ms] ease-linear"
          style={{
            left: heart.x,
            top: heart.y,
            transform: `scale(${heart.scale}) rotate(${heart.rotation}deg) translateY(-${window.innerHeight + 100}px)`,
            opacity: 0.9,
            willChange: 'transform, opacity',
          }}
        >
          <div className="text-love-400 text-4xl">❤️</div>
        </div>
      ))}
    </div>
  );
};