import { Instagram, Search, Share2, Heart } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";

export const InstagramFeature = () => {
  return (
    <section className="py-16 md:py-24 bg-gradient-to-b from-white via-love-50/30 to-white relative overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-4xl font-bold text-love-700 mb-4">
            Share Your Love Song on Instagram
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Make Valentine's Day extra special by sharing your personalized love song
            on Instagram Stories and Reels
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {/* Step 1 */}
          <Card className="relative group hover:shadow-xl transition-all duration-300 border-love-200">
            <CardContent className="p-6">
              <div className="mb-4 bg-love-100 w-12 h-12 rounded-full flex items-center justify-center text-love-600">
                <Search className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-love-700">
                1. Find Your Song
              </h3>
              <p className="text-gray-600">
                Search "lovetunes_[your_code]" in Instagram's search bar using your unique song code
              </p>
            </CardContent>
          </Card>

          {/* Step 2 */}
          <Card className="relative group hover:shadow-xl transition-all duration-300 border-love-200">
            <CardContent className="p-6">
              <div className="mb-4 bg-love-100 w-12 h-12 rounded-full flex items-center justify-center text-love-600">
                <Instagram className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-love-700">
                2. Add to Your Story
              </h3>
              <p className="text-gray-600">
                Tap the song post and use Instagram's "Add to Story" feature to share your love
              </p>
            </CardContent>
          </Card>

          {/* Step 3 */}
          <Card className="relative group hover:shadow-xl transition-all duration-300 border-love-200">
            <CardContent className="p-6">
              <div className="mb-4 bg-love-100 w-12 h-12 rounded-full flex items-center justify-center text-love-600">
                <Share2 className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-love-700">
                3. Customize & Share
              </h3>
              <p className="text-gray-600">
                Add romantic stickers, mentions, and share with your special someone
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Preview Section */}
        <div className="mt-16 text-center">
          <div className="relative inline-block">
            <div className="bg-gradient-to-r from-love-200 via-love-300 to-love-200 p-1 rounded-3xl">
              <div className="bg-white rounded-3xl p-6 relative">
                <div className="flex items-center justify-center space-x-4 mb-4">
                  <Instagram className="w-6 h-6 text-love-600" />
                  <div className="bg-gray-100 rounded-full px-4 py-2 flex items-center">
                    <Search className="w-4 h-4 text-gray-400 mr-2" />
                    <span className="text-gray-600">lovetunes_[your_code]</span>
                  </div>
                </div>
                <div className="animate-pulse flex justify-center">
                  <Heart className="w-8 h-8 text-love-500" />
                </div>
              </div>
            </div>
            
            {/* Decorative Elements */}
            <div className="absolute -top-4 -right-4 w-8 h-8 bg-love-100 rounded-full flex items-center justify-center animate-bounce">
              <Heart className="w-4 h-4 text-love-500" />
            </div>
            <div className="absolute -bottom-4 -left-4 w-8 h-8 bg-love-100 rounded-full flex items-center justify-center animate-bounce delay-100">
              <Heart className="w-4 h-4 text-love-500" />
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-12 text-center">
          <p className="text-love-600 font-medium mb-4">
            Make this Valentine's Day unforgettable with a personalized love song
          </p>
          <p className="text-sm text-gray-500">
            Each song comes with a unique code for easy sharing on Instagram
          </p>
        </div>
      </div>
    </section>
  );
};